.container {
    display: flex;
    position: relative;
    flex-flow: column;
    width: 100%;
    min-height: 100vh;
    height: fit-content;
    flex: 1 0 auto;
}

.cover {
    display: flex;
    position: relative;
    width: 100%;
    min-height: 100vh;
    height: 100vh;
    z-index: 1;
}

.video_container {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 30rem;
    pointer-events: none;
    user-select: none;
    padding-top: 50rem;
    z-index: 2;
}

.logo {
    display: flex;
    max-height: 100%;
    max-width: 50%;
    aspect-ratio: 10/13;
    height: 100%;
    padding: 50rem;
}

.logo > img {
    object-fit: scale-down;
    max-width: 100%;
    max-height: 100%;
    height: 100%;
}

.text {
    display: flex;
    font-size: clamp(2.5em, 0.625em + 6vw, 4em);
    color: rgb(22, 22, 22);
    justify-content: center;
    align-items: center;
    text-wrap: pretty;
    text-wrap:wrap;
    text-align: center;
    min-width: 30%;
    font-weight: bolder;
    /* -webkit-text-stroke-width: 1rem; */
    /* -webkit-text-stroke-color: #e1e1e1; */
    /* flex: 1 0 auto; */
}


@media (max-width: 800px) {
    .content {
      flex-flow: column;
      height: 100dvh;
      justify-content: center;
      align-items: center;
      padding-top: 0;
    }
    .logo {
        align-items: center;
        justify-content: center;
        max-height: 50%;
        max-width: 100%;
        height: 50%;
        width: 100%;
        padding-top: 0;
        padding-bottom: 0;
    }
    .text {
        width: 100%;
    }
  }
  