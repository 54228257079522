.container {
    display: flex;
    flex-flow: column;
    /* gap: 20rem; */
    width: 100%;
    min-height: 100vh;
    padding-top: 100rem;
    height: fit-content;
    flex: 1 0 auto;
    padding-bottom: 40rem;
}

@media (max-width: 800px) {
    .container {
        padding-top: 60rem;
    }
  }
  