.container {
    display: flex;
    width: 100%;
    flex-flow: column;
    height: fit-content;
    /* min-height: 70vh; */
    flex: 1 0 auto;
}

.container.full_h {
    min-height: 100vh;
}

.container.only_one {
    padding-top: 100rem;
    padding-bottom: 40rem;
}

.header {
    font-size: 2em;
    font-weight: bold;
    width: 100%;
    padding: 20rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex: 0 0 auto;
}

.content {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: max-content;
    padding: 0 50rem 0rem 50rem;
    gap: 50rem;
    flex: 1;
}

.content.reverse {
    flex-flow: row-reverse;
}

.only_one .image {
    height: 80%;
}

.image {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    max-width: 40%;
    min-width: 20%;
    height: 100%;
    aspect-ratio: 1/1;
    overflow: hidden;
}

.image img {
    position: absolute;
    border-radius: 50rem;
    object-fit:cover;
    max-width: 90%;
    max-height: 90%;
    /* width: clamp(fit-content, fit-content, 100%); */
    /* height: clamp(fit-content, fit-content, 100%);  */
}

.text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    max-width: 50%;
    min-width: 50%;
    flex: 1;
    text-wrap: pretty;
    text-wrap: wrap;
}

.form, form {
    display: flex;
    flex-flow: column;
    min-width: 30%;
}

form label {
    /* padding-left: 30rem; */
    padding-top: 20rem;
    padding-bottom: 3rem;
}

form button {
    all: unset;
    display: flex;
    position: relative;
    padding: 15rem;
    border-radius: 10rem;
    margin-top: 20rem;
    background-color: rgb(17, 80, 173);
    color: white;
    justify-content: center;
    align-items: center;
    text-align: center;
}

form button:hover::before {
    width: calc(100% + 10rem);
    height: calc(100% + 10rem);
    transition: all 0.3s;
}

form button::before {
    content: "";
    position: absolute;
    width: calc(100% - 10rem);
    height: calc(100% - 10rem);
    /* background-color: rgba(17, 80, 173, 0.4); */
    border: 3rem solid rgba(17, 80, 173, 0.5);
    border-radius: 15rem;
    z-index: -1;
    transition: all 0.3s;
}

form button:active::before {
    background-color: rgba(17, 80, 173, 0.5);
}

form button:hover {
    cursor: pointer;
}

input {
    padding: 10rem;
    border-radius: 8rem;
    border: 2rem solid rgb(114, 114, 114);
    font-size: 1em;
    /* border-color: red; */
}

input:focus {
    outline-color: rgb(0, 0, 0);
    outline-width: 10rem;
}

textarea {
    resize: none;
    width: 100%;
    aspect-ratio: 40/20;
    font-size: 1em;
    padding: 10rem;
}

@media (max-width: 800px) {
    .container.only_one {
        padding-top: 60rem;
        /* padding-bottom: 100rem; */
    }
    .container {
        /* padding-top: 60rem; */
    }
    .content {
      flex-flow: column;
      justify-content: flex-start;
      align-items: center;
      gap: 20rem;
    }
    .content.reverse {
        flex-flow: column;
    }
    .image {
        align-items: center;
        justify-content: center;
        height: clamp(200rem, 200rem, 200rem);
        max-width: 100%;
        padding-top: 0;
        padding-bottom: 0;
    }
    .text {
        width: 100%;
        max-width: 100%;
        flex: 0 1 auto;
        width: 100%;
        height: fit-content;
    }
  }