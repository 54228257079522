.card {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10rem;
  border-radius: 10rem;
  background-color: white;
  max-width: 25%;
  text-wrap: wrap;
  height: auto;
  flex: 1 0 auto;
  font-size: 1.2em;
  border: 3rem solid rgb(240, 240, 240);
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
  transition: all 0.5s;
}

.card:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.08);
    transition: all 0.5s;
}


@media (max-width: 800px) {
  .card {
    max-width: 100%;
  }
}
