* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* box-sizing: padding-box; */
  /* font-size: 1em; */
  line-height: normal;
  /* line-height: 1em; */
  /* font-family: Arial; */
  /* user-select: none; */
  white-space: pre;
  /* font-family: sans-serif; */
  /* font-family: Arial, Helvetica, sans-serif; */
  /* font-family: math; */
  font-family: sans-serif;
  /* font-weight: bold; */
  user-select: none;
}

html,
body,
#root {
  display: flex;
  position: relative;
  height: 100%;
  /* height: 100dvh; */
  width: 100vw;
  justify-content: center;
  /* align-items: center; */
  /* flex-flow: column; */
  /* max-height: 100vh; */
  /* font-size: 0.1vw; */
  /* font-size: 2.4vh; */
  font-size: 0.15vh;
  /* font-size: 1px; */
  /* font-size: 0.3vh; */
  overflow: hidden;
}
