.container {
  display: flex;
  flex-flow: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: fit-content;
  padding: 20rem;
  border-radius: 10rem 10rem 0 0;
  background-color: rgb(51, 51, 51);
  gap: 15rem;
}

.blocks {
  display: flex;
  width: 100%;
  gap: 20rem;
  justify-content: space-evenly;
}

.head1,
.head2 {
  font-weight: bold;
}

p.head2:not(:first-child) {
  padding-top: 10rem;
}

p:not(.head1, .head2) {
  padding-left: 20rem;
}

p:has(> img) {
  display: flex;
  gap: 5rem;
}

p img {
  filter: invert();
  padding: 3rem;
  width: 25px;
  height: 25px;
}

p img:hover {
  cursor: pointer;
}

.container p {
  color: white;
}

@media (max-width: 800px) {
  .blocks {
    flex-flow: column;
  }
  .block {
    width: 100%;
  }
}
