.menu {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  width: 50rem;
  height: 50rem;
  margin: 10rem;
  border-radius: 10rem;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
  padding: 8rem;
}

.menu:hover {
  opacity: 1;
  cursor: pointer;
}

.menu img {
  width: 100%;
  height: 100%;
  filter: invert();
  opacity: 1;
  object-fit: fill;
}

.menu img:last-child {
  display: none;
}
.menu img:first-child {
  display: block;
}

.menu.active img:first-child {
  display: none;
}
.menu.active img:last-child {
  display: block;
}

.mobile_logo {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 60rem;
  z-index: 100;
  margin: 10rem;
}

.container {
  display: flex;
  position: fixed;
  justify-content: center;
  width: 100%;
  height: 80rem;
  top: 0;
  /* left: 0; */
  padding: 0 10rem 10rem 10rem;
  pointer-events: none;
  z-index: 3;
}

.container ul {
  display: flex;
  width: fit-content;
  height: 100%;
  padding: 0 20rem;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 0 0 20rem 20rem;
  gap: 20rem;
  pointer-events: all;
}

.container ul li {
  all: unset;
  color: rgb(255, 255, 255, 0.8);
  padding: 8rem;
}

.container ul li:hover {
  color: white;
  cursor: pointer;
  background-color: rgb(255, 255, 255, 0.08);
  border-radius: 10rem;
}

.container ul li.active {
  color: white;
  text-decoration: underline;
}

.container li img {
  object-fit: scale-down;
  height: 100%;
}

.container li:has(> img) {
  padding: 0;
  margin-right: 50rem;
  height: 32rem;
}

@media (max-width: 800px) {
  .mobile_logo:has(~ .active) {
    display: flex;
  }
  .menu {
    display: flex;
  }
  .menu.active {
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 10rem 10rem 0 0;
  }
  .container.show {
    display: flex;
  }
  .container {
    display: none;
    position: absolute;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    right: 0;
    top: 0rem;
    padding: 0;
  }
  .container ul {
    flex-flow: column;
    position: absolute;
    border-radius: 20rem 0 20rem 20rem;
    height: fit-content;
    padding: 20rem;
    right: 10rem;
    top: 60rem;
    gap: 0;
  }
  .container ul li {
    width: 100%;
    justify-content: center;
    text-align: center;
    padding: 20rem 10rem;
  }
  .container li:has(> img) {
    display: none;
  }
}
