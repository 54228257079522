.container {
    display: flex;
    position: absolute;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    width: 100%;
    height: 100%;
    background-color: rgb(225, 225, 225);
    z-index: -1;
    user-select: none;
    /* pointer-events: all; */
    overflow: hidden;
}

.container p {
    display: flex;
    padding: 10rem;
    font-size: 1.5em;
    /* border: 1px solid red; */
    transition: all 2s;
    color: rgb(210, 210, 210);
}

.container p:hover {
    font-weight: bolder;
    transition: all 0.1s;
}

.container p.blue:hover {
    color: rgb(0, 68, 255);
    text-shadow: rgb(22, 162, 255) 1px 0 10px;
}
.container p.red:hover {
    color: rgb(226, 8, 8);
    text-shadow: rgb(245, 77, 77) 1px 0 10px;
}